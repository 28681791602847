<template>
  <div>
    <tab-bar title="aaa" />
    <div class="content w1400">
      <div class="left">
        <del-info />
        <pro-info />
      </div>
      <div class="right">
        <q-rcode />
        <scroll-box :option="transOpt" @onClick="rollClk" class="mrTop20" />
      </div>
    </div>
  </div>
</template>

<script>
import TabBar from './delLayout/TabBar'
import DelInfo from './delLayout/DelInfo'
import ProInfo from './delLayout/ProInfo'

export default {
  name: 'UsersPageDel',
  data() {
    return {
      transOpt: {
        title: '相关企业',
        list: [],
        isHover: true,
        context: (str) => {
          return ``
        },
      },
    }
  },
  components: {
    TabBar,
    DelInfo,
    ProInfo,
  },
  methods: {
    rollClk(e) {
      console.log('轮播', e)
    },
  },
}
</script>

<style scoped>
.mrTop20 {
  margin-top: 20px;
}
.content {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 100px;
}
</style>
