<template>
  <div class="all">
    <div class="mark">
      <span>产品备注</span>
    </div>
    <div class="imgs">
      <img v-for="i in 3" src="~assets/img/public/noImg.png" />
    </div>
    <div class="desc">
      <span>这里是产品信息内容</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProInfo',
  data() {
    return {}
  },
}
</script>

<style scoped>
.all {
  margin-bottom: 50px;
  width: 800px;
  height: 370px;
}

.mark {
  width: 100%;
  border-bottom: 1px solid #e6ebf5;
}
.mark span {
  position: relative;
  top: 1px;
  display: inline-block;
  padding: 0 3px;
  height: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #25449a;
  border-bottom: 2px solid #25449a;
}

img {
  width: 128px;
  height: 128px;
  margin-right: 20px;
}

.imgs {
  margin-top: 30px;
}

.desc {
  padding: 20px;
  margin-top: 30px;
  width: 800px;
  height: 132px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  border: 1px solid #e2e2e2;
}
</style>
