<template>
  <div class="all">
    <img src="~assets/img/public/noImg.png" />
    <div class="info">
      <div class="row">
        <span>广西贺州桂鑫钢企</span>
        <span class="type">废旧金属</span>
        <span class="type">加工型</span>
      </div>
      <span>详细地址：湖北省十堰市建设中路299号右拐700米左右</span>
      <span>联系电话：19822033942</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DelInfo',
  data() {
    return {}
  },
}
</script>

<style scoped>
.all {
  margin-bottom: 30px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  width: 800px;
  height: 160px;
  border: 1px solid #E6EBF5;
}

img {
  width: 128px;
  height: 128px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  padding: 14px 0;
  height: 128px;
}

.info > span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #303133;
  line-height: 38px;
  opacity: 0.9;
}

.row span {
  margin-right: 10px;
}

.row span:first-child {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #25449a;
  opacity: 0.9;
}

.type {
  display: inline-block;
  width: 70px;
  height: 24px;
  line-height: 24px;
  background: #dcedff;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #25449a;
}
</style>
